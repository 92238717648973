// Styles for panels and panes.
// -----------------------------------------------------------------------------

fieldset.form-wrapper.panel.panel-default {
	margin-top: 30px;
}

/* center infoboxes and red border bottom on H2 headlines */
#panel-bootstrap-region-left_info,
#panel-bootstrap-region-right_info,
#panel-bootstrap-region-top_left,
#panel-bootstrap-region-top_center,
#panel-bootstrap-region-top_right,
#panel-bootstrap-region-bottom_left,
#panel-bootstrap-region-bottom_center,
#panel-bootstrap-region-bottom_right {
	h2.pane-title {
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 2.4rem;
	}
	.pane-content {
		border-top: $ktp-devider;
	}
}
#panel-bootstrap-row-main h2 {
	display: none;
}
//#panel-bootstrap-region-left_info, #panel-bootstrap-region-right_info{
//	.pane-content {
		//border-bottom: 2px solid #C90000;
//	}
//}
.front-center-text {
	h2.pane-title {
		float: left;
	}
}
.front-center-text-right {
	position: absolute;
	bottom: 0px;
	right: 15px;
	a, a:visited {
	 color: black;
		text-decoration: none;
	}
	a:hover{
		text-decoration:underline;
		color: $ktp-base-color;
	}
}
.avail-apt-header {
	border-top: $ktp-devider;
}

/* adjust panels right for left menu */
#layout-main-row {
	padding-left: 0;
	padding-right: 250px;
}

#layout-top-row, #layout-info-row, #layout-content-row {
	margin-left: 250px;
}

#layout-top-row h2.pane-title {
	display: none;
}
#sidebar-wrapper {
	width: 250px;
	height: 100%;
}

@media (max-width: 767px) {
	#sidebar-wrapper {
		width: 0;
		display: none;
	}
	#layout-top-row, #layout-info-row, #layout-content-row {
		margin-left: 0;
	}
	#layout-main-row {
		padding-right: 0;
	}
	.front-center-text, .avail-apt-header {
		//text-align: center;
		h2 {
			font-size: 16px;
		}
	}
	.front-center-text-right {
		bottom: -45px;
		h4 {
			font-size: 12px;
			a {
				text-decoration: underline;
			}
		}
	}
} 	/* end adjust */

#panel-bootstrap-column-2 {
	.pane-nyheder {
		min-height: 353px;
	}
}
.node-afdeling-flexslider h2, .node-dokument-header h2 {
	display: none;
}

//.view-afdelingsdokument {
	//.node-afdelingsdokument {
		//border-top: 2px solid #C90000;
		//.contextual-links-wrapper{
			// margin-top: 100px;
		//}
		/*.red-line, .ktp-devider {
			border-bottom: $ktp-devider;
			width: 100%;
		}*/
	//}
	//.node-afdelingsdokument h2 > a {
	//	color: black;
	//	text-decoration: none;
	//}
//}

//.view-dokumenter {
	//.node-dokument {
		//border-bottom: 2px solid #C90000;
		//.contextual-links-wrapper{
			// margin-top: 100px;
		//}
		/*.red-line, .ktp-devider {
			border-bottom: $ktp-devider;
			width: 100%;
		}*/
	//}
	//.node-dokument h2 > a {
		//color: $ktp-black;
	//}
//}

//.node-demo-dokument tr h4,
.node-info-dokument tr h4{
	a {
		color: black;
	}
	a:hover {
		text-decoration: none;
		color: $ktp-panels-hover-color;
	}
}
.view-nyheder {
	a {
		color: black;
		font-size: 1.25em;
		font-weight: 700;
	}
	a:hover {
		text-decoration: none;
		color: $ktp-panels-hover-color;
	}
}

div.ktp-scrollable {
	position: relative;
}

span.ktp-scroll-anchor {
	display: block;
	height: 70px;
	//margin-top: -70px;
	visibility: hidden;
}
