// Styles for Menus and Navs
// -----------------------------------------------------------------------------

/* right main menu */
#main-wrapper {
	margin-top: 70px;
}
body {
	position: relative;
}
body.not-logged-in .navbar-fixed-top {
	top: 0px !important;
	margin-bottom: 0px;
}

.ktp-navbar {
	position: absolute;
	//background-color: #fff;
	//border: 1px solid #080808;
}

.ktp-navbar {
	right: -1px;
}

.navbar-nav>li>a.beboer:before {
	background-image: url(/sites/default/themes/ktp_theme_3/beboer-logo.png);
	background-repeat: no-repeat;
	background-position: 0px top;
	content: "";
	display: inline-block;
	height: 18px;
	margin-right: -2px;
	vertical-align: bottom;
	width: 26px;
}

.navbar-nav>li>a.beboer:hover:before {
	background-image: url(/sites/default/themes/ktp_theme_3/beboer-logo-dark.png);
}

@media (min-width: 768px) {
	.ktp-navbar {
		position: static;
		border: none;
	}
}

.navbar-default {
	border: none;
	background-color: $ktp-navbar-dropdown-color;
	//max-height: 50px;
}

.navbar-nav.navbar-left a {
	line-height: 40px;
}

.navbar-nav > li > .dropdown-menu {
	padding: 15px;
}

.navbar-collapse.collapse {
	//display: block!important;
}

.navbar-nav>li, .navbar-nav {
	float: left !important;
}


.navbar-nav.navbar-right:last-child {
	margin-right: 0px !important;
}

.navbar-right {
	float: right!important;
	.badge {
		min-width: 9px;
		padding: 4px 5px;
		font-size: 9px;
		line-height: 0.75;
		border-radius: 9px;
	}
	li.ktp-fb-follow {
		a {
			//color: #29487D;
			color: #777;
			text-decoration: none;
		}
		a:link, a:visited{
			text-decoration: none;
		}
		a:hover {
			//color:#3C5898;
			color: #29487D;
		}
	}
}

.nav>li>a.ktp-cart,
.nav>li>#ktp-cart-badge-pending {
	display: none;

}
body.ktp-search-has-pending {
	.nav>li>a.ktp-cart,
	.nav>li>#ktp-cart-badge-pending {
		display: block;
	}
}

#ktp-user-badge-notify, #ktp-cart-badge-pending {
	background: red;
	position: absolute;
	top: 25px;
	left: 4px;
	z-index: 2000;
}

@media (max-width: 767px) {

		.navbar .open .dropdown-menu.no-collapse {
			background-color: $ktp-navbar-dropdown-color;
			border-radius: 4px;
			border: 1px solid rgba($ktp-black, 0.2);
			box-shadow: 0 5px 10px rgba($ktp-black, 0.2);
			//display: none;
			float: left;
			position: absolute;
			top: 100%;
			left: -100px;
		}
		.navbar .open .dropdown-menu.no-collapse.logged-in {
			right: 0;
			left: auto;
		}
		#ktp-user-badge-notify {
			top: 20px;
		}
}

.overlay {
	background-color: rgba($ktp-black,0.5);
	position: absolute;
	top: -70px;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2000;
	opacity: 0;
	visibility: hidden;
}

nav.right-menu {
	background: $ktp-mainmenu-bg-color;
	display: block;
	height: 100%;
	overflow: auto;
	position: fixed;
	right: -20em;
	font-size: 15px;
	top: 0px;
	width: 20em;
	z-index: 2000;

	transition: right 0.3s ease-in-out 0s;
	-webkit-transition: right 0.3s ease-in-out 0s;
	-moz-transition: right 0.3s ease-in-out 0s;
	-o-transition: right 0.3s ease-in-out 0s;
}

.nav-expanded nav.right-menu {
	right: 0;
}

body.nav-expanded {
	margin-left: 0em;
	transition: right 0.4s ease-in-out 0s;
	-webkit-transition: right 0.4s ease-in-out 0s;
	-moz-transition: right 0.4s ease-in-out 0s;
	-o-transition: right 0.4s ease-in-out 0s;
}

.nav-expander, .nav-expander:hover {
	color: $ktp-navbar-hover-color;
	text-decoration: none;
	cursor: pointer;
}


#nav-close {
	padding-right: 10px;
}

body.admin-menu .navbar-fixed-top {
	top: 20px !important;
	margin-bottom: 20px;
}

.main-menu {
	padding-top: 12px;

	.menuform {
		color: $ktp-mainmenu-font-color;
		position: absolute;
		top: 0;
		left: 8px;

		.form-wrapper{
			background-color: $ktp-mainmenu-bg-color;
		}
		.forminput {
			height: 15px;
			font-size: 10px;
		}
		.formlabel {
			height: 15px;
		}
	}
}

.main-menu li {
	//border-bottom: 1px solid #323949;  lighten( #dad9d4, 50%);
	border-bottom: 1px solid lighten( $ktp-mainmenu-bg-color, 20%);
	margin-left: 20px;
	font-size: 18px;
	padding: 12px;
}

.main-menu li a {
	//color: #6F7D8C;
	color: $ktp-mainmenu-font-color;
	text-decoration: none;
}

.main-menu li a:hover {
	color: $ktp-mainmenu-hover-color;
	text-decoration: none;
}

.main-menu li .sub-nav {
	border-bottom: 0px;
	padding: 4px;
}

.main-menu a .caret {
	width: 0;
	height: 0;
	display: inline-block;
	vertical-align: top;
	border-top: 4px solid $ktp-mainmenu-border-color;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
	content: "";
	margin-top: 8px;
}

.main-menu a:hover .caret {
	border-top-color: $ktp-mainmenu-border-color;
}

.main-menu li.open > a > .caret {
	border-top: none;
	border-bottom: 4px solid $ktp-mainmenu-border-color;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

.main-menu li.open > a:hover > .caret {
	border-bottom-color: $ktp-mainmenu-border-color;
}

.icon:before {
	font-family: 'FontAwesome';
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-transform: none;
	content: '\f060';
}

.main-menu li > a > span.icon {
	float: right;
	margin: 0.1em 1.7em -0.1em 0;
	opacity: 0;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;

}

.main-menu li > a:hover > span.icon {
	float: right;
	margin: 0.1em 0.8em -0.1em 0;
	opacity: 1;
}

.main-menu li.active{
	span.icon {
		float: right;
		margin: 0.1em 0.8em -0.1em 0;
		opacity: 1;
	}

}

/* push content below admin menu */
#admin-menu {
	z-index: 2000;
	margin-bottom: 20px;
}


/* Afdelingsdokumenter og Dokumenter menu venstre top   */

.branch .field-label {
	display: none;
}

/*  ny menu */
#panel-bootstrap-region-menu  {
	/*  don't show menu title */
	h2.pane-title {
		display: none;
	}

	/* Left sidebar menu for Dokumenter and Menupunkt */
	.bs-docs-sidebar, nav.bs-docs-sidebar {
		padding-left: 20px;
		margin-bottom: 20px;
		margin-top: 10px;

		ul.ktp-node-tree {
			max-width: 230px;
		}

		/*  Set menu always on top */
		.fixed {
			position: fixed;
		}
		.affix {
			position: fixed;
			top: 50px;
		}

		li.title h3 {
			color: $ktp-mainmenu-font-color;
		}
		li.title h3 > a {
			color: $ktp-mainmenu-font-color;
		}
		.item-list ul li {
			margin: 2px 0 0 0 ;
			padding: 0;
		}

		.nav {
			/* all links */

			li >  a {
				color: #999;
				//color: red;
				border-left: 2px solid transparent;
				padding: 4px 10px;
				font-size: 14px;
				font-weight: 400;
			}

			/* nested links */
			.nav > li > a {
				padding-top: 1px;
				padding-bottom: 1px;
				padding-left: 5px;
				font-size: 13px;
			}

			/* nested links */
			.nav > li {
				padding-top: 1px;
				padding-bottom: 1px;
				padding-left: 10px;
				font-size: 13px;
			}

			/* active & hover links */
			.active > a,
			li > a:hover,
			li > a:focus {
				//color: #563d7c;
				color: $ktp-base-color;
				text-decoration: none;
				background-color: transparent;
				border-left-color: $ktp-base-color;
			}
			/* all active links */
			.active > a,
			.active:hover > a,
			.active:focus > a {
				font-weight: 700;
			}
			/* nested active links */
			.nav > .active > a,
			.nav > .active:hover > a,
			.nav > .active:focus > a {
				font-weight: 500;
			}

			/* hide inactive nested list */
			ul.nav {
				display: none;
			}
			/* show active nested list */
			.active > ul.nav {
				display: block;
				//color:red;
			}
		}
	}
}
/* End ny menu */