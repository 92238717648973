
.top-10 {
	margin-top: 10px;
}
.top-20 {
	margin-top: 20px;
}

.apt-type-header {
	border-top: 1px solid #ccc;
}

.apt-type-info {
	.txt-long {
		display: inherit;
	}
	.txt-short{
		display: none;
	}
}

.apt-type-pt {
	.field-label {
		display: none;
	}
	img {
		margin: 0 auto;
	}
}

.entity-ktp-apartment-type {
	.content, .actions-inner {
		padding: 15px;
	}
}

/*.user-apartment-list {
	.contextual-links-region{
		outline-color: green;
	}
}*/


.apt-type-footer {
	//position: relative;
	height: 40px;
	background-color: $ktp-card-panel-footer-bg-color;
	//padding-bottom: 30px;
	border-bottom: $ktp-card-panel-border-bottom;
	//border-bottom-right-radius: 0px;
	//border-bottom-left-radius: 0px;
	//padding: 15px 15px;

	.apt-type-footer-right {
		a {
			color: $ktp-card-panel-footer-right-color;
			font-family: 'Oswald';
			font-weight: bold;
			font-size: 14px
		}
		a:hover {
			color: $ktp-card-panel-border-footer-right-hover-color;
			font-family: 'Oswald';
			font-size: 14px;
			font-weight: bold;
			text-decoration: underline;
		}
	}
}

@media (max-width: 420px) {
	.col-xs-12, .col-sm-12 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.apt-type-title {
		font-size: 90%;
	}
	.apt-type-top {
		padding-left: 0;
		padding-right: 0;
	}
	.apt-type-info {
		font-size: 90%;
		padding-left: 0;
		padding-right: 0;
		.txt-long {
			display: none;
		}
		.txt-short{
			display: inherit;
		}
	}
	.apt-type-footer-right {
		font-size: 80%;
		padding-right: 10px;
	}
}