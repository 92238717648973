
.node-nyhed {
	.content {
		.field-name-title-field{
			.field-item h2 {
				display: none;
			}
		}
	}
}

/* Left sidebar menu for News */
.bs-docs-sidebar, nav.bs-docs-sidebar {
	padding-left: 20px;
	margin-bottom: 20px;
	margin-top: 10px;

	ul.ktp-node-tree {
		max-width: 230px;
	}

	/*  Set menu always on top */
	.fixed {
		position: fixed;
	}
	.affix {
		position: fixed;
		top: 50px;
	}

	li.title h3 {
		color: $ktp-black;
	}
	li.title h3 > a {
		color: $ktp-black;
	}

	.nav {
		/* all links */

		li >  a {
			color: $ktp-news-left-menu-color;
			//color: red;
			border-left: 2px solid transparent;
			padding: 4px 10px;
			font-size: 14px;
			font-weight: 400;
		}

		/* nested links */
		.nav > li > a {
			padding-top: 1px;
			padding-bottom: 1px;
			padding-left: 5px;
			font-size: 13px;
		}

		/* nested links */
		.nav > li {
			padding-top: 1px;
			padding-bottom: 1px;
			padding-left: 10px;
			font-size: 13px;
		}

		/* active & hover links */
		.active > a,
		li > a:hover,
		li > a:focus {
			color: $ktp-news-left-menu-hover-color;
			text-decoration: none;
			background-color: transparent;
			border-left-color: $ktp-news-left-menu-border-color;
		}
		/* all active links */
		.active > a,
		.active:hover > a,
		.active:focus > a {
			font-weight: 700;
		}
		/* nested active links */
		.nav > .active > a,
		.nav > .active:hover > a,
		.nav > .active:focus > a {
			font-weight: 500;
		}

		/* hide inactive nested list */
		ul.nav {
			display: none;
		}
		/* show active nested list */
		.active > ul.nav {
			display: block;
		}
	}
}