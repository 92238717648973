// Styles for ktp_hr
// -----------------------------------------------------------------------------

.property_picture img {
	min-width: 100px;
}


/*  vertical */
div.ktphr.card.vertical .card {
	float: left;
	margin: 0.5em;
	min-width: 150px;
}

div.ktphr.card.vertical .cells .cell {
	line-height: 1em;
	min-height: 1em;
}


/*  Horizontal */
div.ktphr.card.horizontal .card {
	float: left;
	margin: 0.5em;
	min-width: 300px;
}

div.ktphr.card.horizontal .cells .cell {
	line-height: 1em;
	min-height: 1em;
}

div.ktphr.card.horizontal .cells {
	padding: 0 0.5em;
}

div.ktphr.card.horizontal .picture,
div.ktphr.card.horizontal .cells {
	display: inline-block;
	float: left;
}
