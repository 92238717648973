// Styles for ktp-flexslider
// -----------------------------------------------------------------------------

/* adjust frontpage flexslider images to full width  */
.flexslider {
	border: none !important;
	margin: 0 -30px 60px -30px !important;
}

/* set flexsliders with left sidebar menu to standard width  */
div#layout-top-row {
	div#panel-bootstrap-region-center {
		.flexslider {
			border: none !important;
			margin: 0 0 60px !important;
		}
	}
}

