// Styles for ktp Apartments
// -----------------------------------------------------------------------------

.apartment-add-btn {
	padding-top: 20px;
}

.apartment-center .btn {
	display: inline-block;
	padding: 6px 12px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	border: 1px solid transparent;
	border-radius: 4px;
}

.apartment-center .jq-toggle {
	padding-top: 50px;
}

.apartment-second-title {
	color: $ktp-base-color;
}
.apartment-remark {
	color: $ktp-red;
	padding-top: 5px;
}

table.apartment-info-table > tbody > tr {
	border-bottom: 1px solid $ktp-grey;
}

.apartment-carousel-title-text {
	position: absolute;
	top: 0;
	width: 100%;
	background: rgba($ktp-white,0.4);
	color: $ktp-black;
}
.apartment-carousel-icons {
	position: absolute;
	//width: 100%;
	top: 13px;
	right: 10px;
	//background: rgba(255,255,255,0.4);

	.pt-icon img {
		//height: 39px;
		//margin: 1px 12px 25px 12px;
		height: 32px;
		margin: 1px 12px 19px 12px;
	}
	a {
		color: $ktp-black;
		text-decoration: none;
	}
	a:hover {
		cursor: pointer;
		color: $ktp-base-color;
	}
	a:visited, a:focus  {
		border: none;
		outline: none;
	}
}

.apartment-carousel-inner > .item > picture > img {
	max-width: 100%;
}

.apartment-carousel-control-left {
	background: none !important;
	width: 5%;
}
.apartment-carousel-control-right {
	background: none !important;
	width: 5%;
}

.apartment-carousel-indicators {
	bottom: -50px;
	.active {
		margin: 0;
		width: 11px;
		height: 11px;
		background: rgba($ktp-black, 0.9);
		border: none;
	}
	li {
		float: none;
		margin-left: 3px;
		margin-right: 3px;
		border: none;
		background: rgba($ktp-black, 0.5);

		/*display: inline-block;
		width: 10px;
		height: 10px;
		margin: 1px;
		text-indent: -999px;
		border: 1px solid #fff;
		border-radius: 10px;
		cursor: pointer;
		background-color: #000 \9;
		//background-color: transparent;*/
	}
}

/*.apartment-modal-dialog {
	position: absolute;
	top: 15%;
	right: -15px;
	bottom: 0;
	left: 0;
	z-index: 1050;
	overflow: auto;
	overflow-y: auto;
}*/

.apt-avail-modal-body img{
	margin-left: auto;
	margin-right: auto;
}

table.apartment-info-table {
	tbody {
		border-top: none;
		border-bottom: 2px solid $ktp-black;
	}
	tbody > tr > td.apartment-info-td {
		padding: 5px;
		border: 0px;
	}
}

.ktp-fb-share-btn {
	margin-right: 5px;
}

.SOGEFELT {
	padding: 10px;
	display: inline-block;
}
.SOGEFELT .fa-check {
	color: $ktp-green;
}

.SOGEFELT .fa-times {
	color: $ktp-base-color;
}

@media (max-width: 768px) {
	.apartment-carousel-title-text {
		text-align: left;
		//margin-left: 30px !important;
		h3 {
			font-size: 20px;
			margin-top: 10px;
			margin-bottom: 10px;
			padding-left: 20px;
		}
	}
	.apartment-carousel-icons {
		top: 10px;
		right: 23px;
		.pt-icon img {
			height: 19px !important;
			margin: 1px 9px 18px 9px !important;
		}
		i.fa {
			font-size: 1.75em;
		}
	}
	.apartment-first-title{
		h2 {
			font-size: 20px;
			font-weight: bold;
		}
	}
	.apartment-description{
		h3 {
			font-size: 20px;
			font-weight: bold;
		}
	}
	.apartment-carousel-indicators {
		//left: 0;
		//max-width: 60%;
		//margin-left: 0;
		//text-align: left;
	}
	.apartment-add-btn {
		padding-top: 15px;
	}

	.table-responsive{
		border: none !important;
	}
}

@media (max-width: 1199px) {
	.apartment-description {
		//border-bottom: 1px solid #C90000;
		border-bottom: $ktp-devider;
	}
	.apartment-info-title {
		margin-top: 20px;
	}
}
.apartment-modal-body #map-canvas {
	min-height: 300px;
}

.apartment-modal-dialog {

	img.apartment-gallery-all {
		padding: 4px 0px 4px 0px;
	}
}

.apartment-modal-body {
	overflow: hidden;
}

.apartment-modal-carousel, .apartment-modal-gallery{
	.ktp-caption {
		background-color: $ktp-black;
		opacity: 0.5;
		bottom: 0;
		padding-top: 0;
		padding-bottom: 0px;
		left: 0;
		right: 0;
	}

	.left.carousel-control, .right.carousel-control {
		background-image: none;
		width: 5%;
	}
	span.glyphicon {
		background-color: $ktp-grey;
	}
}

/*  keep background opacity only on first modal */
.modal-backdrop+.modal-backdrop {
	opacity : 0;
}