
body.page-wb-search {
	.navbar{
		min-height: 51px;
	}
	.search-card-panel-heading {
		.search-card-cover-image {
			height: 200px;
			opacity: 0.35;
			margin-top: 15px;
		}
	}
	.search-card-description {
		overflow-y: auto;
		border-top: 1px solid #ddd;
		max-height: 233px;
		min-height: 233px;
	}
	.search-card-apply-form {
		.admin-menu-site-wbhosting-lan {
			display: none;
		}
	}
  #ktp-search-app-entrypoint {
    top: 50px !important;
  }
}

/* adjusted css for search apply form inside iFrame */
body.page-bolig-uden-ventetid-search-apply-form{
	margin-top: -60px;
	.navbar-default {
		display: none;
	}
	.breadcrumb {
		display: none;
	}
	.footer {
		display: none;
	}
	.to-top {
		display: none;
	}
}


form#ktp-available-apartment-apply-form{
	padding: 10px !important;
}


