// Styles for Nodes
// -----------------------------------------------------------------------------

/* remove 'close window' in payment window  */
.payment-content .popup-only {
	display: none;
}

.tabs,
.nav-tabs {
	margin: 50px 0 18px;
}

div#messages {
	margin-top: 50px;
}

.node-nyhed{
	.field-name-title-field {
		padding: 10px 0px 10px 0px;
	}
	.field-name-field-dato {
		margin-bottom: 10px;
		span {
			font-weight: bold;
		}
	}
}
.node-nyhed-form, .node-dokument-form {
	margin-top: 20px;
}

.node-flexslider h2 {
	display: none;
}

.embedded-node .ktp-scroll-anchor,
.embedded-node > .node > h3,
.embedded-node .ktp-devider {
	display: none;
}


.ktp-scrollable, .node-menupunkt, .node-dokument {
	.ktp-devider {
		border-bottom: $ktp-devider;
		width: 100%;
	}
}

.modal {
	text-align: center;
	padding: 0!important;
}

/*.modal:before {
	content: '';
	display: inline-block;
	//height: 100%;
	vertical-align: middle;
	margin-right: -4px;
}*/

.ktpModal {
	.ktp-caption {
		background-color: #000;
		opacity: 0.5;
		bottom: 0;
		padding-top: 0;
		padding-bottom: 0px;
		left: 0;
		right: 0;
	}

	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		width: auto;
	}
	.left.carousel-control, .right.carousel-control {
		background-image: none;
		width: 5%;
	}
	span.glyphicon {
		background-color: grey;
	}
}

.ktp-modal {
	&:before {
		display: none !important;
	}

	&> .region {
		padding: 10px;
		margin: 20px;
		background: white;
		border: 1px solid #333;
		box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.333);
	}
}

body.admin-menu .ktp-modal > .region {
	/* Compensate for admin-menu */
	margin-top: 40px;
}






