// Styles for Forms
// -----------------------------------------------------------------------------

/*#ds-edit-view-mode-form, #node-type-form,
#node-menupunkt-form, #path-admin-form,
#menupunkt-node-form, #menu-edit-item,
#views-ui-edit-form, #flexslider-node-form,
#galleri-node-form, #image-style-add-form, #image-effect-form,
#image-style-delete-form {
	margin-top: 20px;
}*/

.node-form, #filefield-paths-settings-form {
	margin-top: 20px;
}