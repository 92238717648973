// Styles for ktp Available apartment cards
// -----------------------------------------------------------------------------

.card-panel {
	border-radius: 0px;
}
.card-panel > .card-panel-body > img {
	width: 100%;
	height: 100%;
}

.pt-icon img {
	height: 25px;
	margin: 1px 12px 17px 12px;
}

.card-panel-heading {
	padding: 0;
}
h4.card-title {
	margin-top: 0px;
}

.card-panel-footer {
	position: relative;
	background-color: $ktp-card-panel-footer-bg-color;
	padding-bottom: 30px;
	border-bottom: $ktp-card-panel-border-bottom;
	border-bottom-right-radius: 0px;
	border-bottom-left-radius: 0px;
	.footer-right {
		a {
			color: $ktp-card-panel-footer-right-color;
			font-family: 'Oswald';
			font-weight: bold;
			font-size: 14px
		}
		a:hover {
			color: $ktp-card-panel-border-footer-right-hover-color;
			font-family: 'Oswald';
			font-size: 14px;
			font-weight: bold;
			text-decoration: underline;
		}
	}
	.footer-left{
		position: absolute;
		top: 6px;
		a {
			color: $ktp-card-panel-footer-left-color;
			text-decoration: none;
		}
		a:hover {
			cursor: pointer;
			color: $ktp-card-panel-border-footer-left-hover-color;
		}
		a:visited, a:focus  {
			border: none;
			outline: none;
		}
	}
}
table.table > tbody > tr > td.card-td {
	border: 0px;
}

.card-panel-modal-dialog {
	img.card-panel-gallery-all {
		padding: 4px 0px 4px 0px;
	}
}
.card-panel-modal-body {
	overflow: hidden;
}
.card-panel-modal-carousel, .card-panel-modal-gallery {
	.ktp-caption {
		background-color: $ktp-black;
		opacity: 0.5;
		bottom: 0;
		padding-top: 0;
		padding-bottom: 0px;
		left: 0;
		right: 0;
	}

	.modal-dialog {
		display: inline-block;
		text-align: left;
		vertical-align: middle;
		//width: auto;
	}
	.left.carousel-control, .right.carousel-control {
		background-image: none;
		width: 5%;
	}
	span.glyphicon {
		background-color: $ktp-grey;
	}
}

/* below is css for available apartments cards carousel */
.view-display-id-selected_available_apartments_as_cards h1{
	display: none;
}

.avail-apt-card-carousel-top {
	margin: 0 -15px 0 -15px;
	ol.avail-apt-carousel-indicators {
		bottom: -20px;
		.active {
			background-color: rgba($ktp-black, 0.9) !important;
			margin: 1px;
		}
		li {
			background-color: rgba($ktp-black, 0.5) !important;
			width: 12px;
			height: 12px;
		}
		li:hover {
			background-color: rgba($ktp-black, 0.7) !important;
		}
	}
	.apartment-doc-control {
		background-image: none;
		width: 20px;
		top: 100%;
	}
	a.apartment-doc-control {
		opacity: 1.0;
	}
	a.apartment-doc-control:hover {
		opacity: 0.5;
	}
}
@media all and (max-width : 422px) {
	.avail-apt-card-carousel-top {
		ol.avail-apt-carousel-indicators {
			bottom: -30px;
		}
	}
}
.image-container {
	visibility: visible;
	position: absolute;
	top: 128px;
	right: 0px;
	left: 0px;
	padding: 15px;
}
.dummy-card-panel-heading, .dummy-card-panel-body,
.dummy-card-title, .dummy-table, .dummy-card-panel-footer {
	visibility: hidden;
}
.dummy-card-panel{
	border: hidden;
}

.avail-apt-card-carousel-top {
	& .cloneditem-1,
	& .cloneditem-2,
	& .cloneditem-3 {
		display: none;
	}
}

/*  make carousel images full width */
.apartment-carousel-inner {
	width: auto;
	margin-left: -30px;
	margin-right: -30px;
}

.captcha {
  margin: 0 auto 10px auto;
  display: inline-block;
}


@media all and (min-width: 768px) {
	.avail-apt-card-carousel-top {
		& .carousel-inner {
			> .active.left,
			> .prev {
				left: -50%;
			}
			> .active.right,
			> .next {
				left: 50%;
			}
			> .left,
			> .prev.right,
			> .active {
				left: 0;
			}
			& .cloneditem-1 {
				display: block;
			}
		}
	}
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
	.avail-apt-card-carousel-top {
		& .carousel-inner {
			> .item.active.right,
			> .item.next {
				-webkit-transform: translate3d(50%, 0, 0);
				transform: translate3d(50%, 0, 0);
				left: 0;
			}
			> .item.active.left,
			> .item.prev {
				-webkit-transform: translate3d(-50%, 0, 0);
				transform: translate3d(-50%, 0, 0);
				left: 0;
			}
			> .item.left,
			> .item.prev.right,
			> .item.active {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				left: 0;
			}
		}
	}
}
@media all and (min-width: 992px) {
	.avail-apt-card-carousel-top {
		& .carousel-inner {
			> .active.left,
			> .prev {
				left: -50%;
			}
			> .active.right,
			> .next {
				left: 50%;
			}
			> .left,
			> .prev.right,
			> .active {
				left: 0;
			}
			& .cloneditem-2,
			& .cloneditem-3 {
				display: block;
			}
		}
	}
}

@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
	.avail-apt-card-carousel-top {
		& .carousel-inner {
			> .item.active.right,
			> .item.next {
				-webkit-transform: translate3d(33%, 0, 0);
				transform: translate3d(33%, 0, 0);
				left: 0;
			}
			> .item.active.left,
			> .item.prev {
				-webkit-transform: translate3d(-33%, 0, 0);
				transform: translate3d(-33%, 0, 0);
				left: 0;
			}
			> .item.left,
			> .item.prev.right,
			> .item.active {
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
				left: 0;
			}
		}
	}
}


